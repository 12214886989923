import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/simples/Navbar';
import Home from './pages/home/Home';
import Simulador from './pages/simulador/Simulador';

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={"/simulador"} element={<Simulador />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;