import { Box, Typography, Slide } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ContactoModal from "../frames/ContactoModal"
import CursorLuz from "./CursorLuz"

export default function Navbar() {
    const [widthBackground, setWidthBackground] = useState()
    const [leftBackground, setLeftBackground] = useState()
    const [openContacto, setOpenContacto] = useState(false)

    const secciones = [
        { id: 0, nombre: "Home", url: "/" },
        { id: 1, nombre: "Simulador", url: "/simulador" },
        { id: 2, nombre: "Contacto", funcion: () => setOpenContacto(true) },
    ]

    useEffect(() => {
        let pathName = window.location.pathname;
        let seccionActual = secciones.find(seccion => seccion.url === pathName);
        let elemento = document.getElementById(seccionActual.id);
        setWidthBackground(elemento.offsetWidth);
        setLeftBackground(elemento.offsetLeft);
    }
        , [])

    const navigate = useNavigate();


    function handleSelectSeccion(event) {
        const seccion = event.currentTarget;
        setWidthBackground(seccion.offsetWidth);
        setLeftBackground(seccion.offsetLeft);
        navigate(secciones[seccion.id].url)
    }

    return (
        <Slide
            direction="down"
            in={true}
            timeout={500}
            mountOnEnter
            unmountOnExit
        >
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                paddingTop: 5,
                zIndex: 10,
            }}>
                <Box sx={{
                    backgroundColor: "#f68c3e",
                    display: "flex",
                    gap: { xs: 1, sm: 4 },
                    paddingY: { xs: .3, sm: 1.5 },
                    paddingX: { xs: 1, sm: 3 },
                    borderRadius: 10,
                    position: "relative",
                    boxShadow: 5,
                }}>
                    {secciones.map(seccion => {
                        return (
                            <Box
                                onClick={seccion.url ? handleSelectSeccion : seccion.funcion}
                                key={seccion.id}
                                id={seccion.id}
                                sx={{
                                    cursor: "pointer",
                                    paddingX: 2,
                                    paddingY: 1,
                                    borderRadius: 8
                                }}
                            >
                                <Typography variant="h6" color={"white"} fontWeight={600}>{seccion.nombre}</Typography>
                            </Box>
                        )
                    })}

                    <Box sx={{
                        position: "absolute",
                        bottom: "50%",
                        left: `${leftBackground}px`,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        width: `${widthBackground}px`,
                        height: "40px",
                        borderRadius: 10,
                        transition: "0.5s",
                        transform: "translateY(50%)",
                        zIndex: 11
                    }} />

                </Box >
                <CursorLuz />
                <ContactoModal open={openContacto} setOpen={setOpenContacto} />
            </Box>
        </Slide>
    )
}