import React, { useEffect, useState } from 'react';
import { Grid, Typography, Slider, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';


export default function Simulador() {
    const [monto, setMonto] = useState();
    const [interesAnual, setInteresAnual] = useState();
    const [minimo, setMinimo] = useState();
    const [maximo, setMaximo] = useState();
    const [cuotas, setCuotas] = useState([6, 9, 12]);

    useEffect(() => {
        fetch("https://admin.tucredito.ar/secciones/obtenerSecciones/")
            .then(response => response.json())
            .then(resp => {
                let data = resp.secciones
                let seccion = data[4].data
                setInteresAnual(seccion[2].valor)
                setMonto(seccion[0].valor)
                setMinimo(seccion[0].valor)
                setMaximo(seccion[1].valor)
                setCuotas(seccion[3].valor)
            })
    }, [])


    function calcularInteres(cuota) {
        let interesMensual = ((interesAnual * 30) / 365) / 100;
        let cuotaSinIva = monto * (interesMensual / (1 - Math.pow(1 + interesMensual, -cuota)));
        let interes = cuotaSinIva - monto / cuota;
        let iva = interes * 0.21;
        return cuotaSinIva + iva;
    }

    const series = [
        { data: cuotas.map(cuota => calcularInteres(cuota)) }
    ]

    if (!interesAnual || !minimo || !maximo || !cuotas) {
        return;
    }


    return (
        <Grid item container sx={{
            height: "100vh",
            alignItems: "flex-end",
            overflowY: "auto",
        }}>
            <Grid item container xs={12} md={6} sx={{
                height: { xs: "auto", md: "80vh" },
                flexDirection: "column",
                alignItems: "center",
                flexWrap: "nowrap",
                paddingX: { xs: 2, md: 20 },
                paddingTop: { xs: 18, md: 0 },
            }}>
                <Grid item container xs={10} sm={12} sx={{
                    height: "10vh",
                }}>
                    <Typography variant="h5" sx={{
                        color: "white",
                        fontWeight: 600,
                    }}>Prestamo: ${monto}</Typography>

                    <Slider
                        value={monto}
                        onChange={(e, value) => setMonto(value)}
                        min={minimo}
                        max={maximo}
                        step={null}
                        marks={Array.from({ length: Math.floor((maximo - minimo) / 50000) + 1 }, (_, i) => ({ value: minimo + i * 50000 }))}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `$${value}`}
                        sx={{
                            color: '#f68c3e',
                            height: 8,
                            '& .MuiSlider-track': {
                                border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                height: 24,
                                width: 24,
                                backgroundColor: '#fff',
                                border: '2px solid currentColor',
                                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                    boxShadow: 'inherit',
                                },
                                '&::before': {
                                    display: 'none',
                                },
                            },
                            '& .MuiSlider-valueLabel': {
                                lineHeight: 1.2,
                                fontSize: 12,
                                background: 'unset',
                                padding: 1,
                                fontWeight: 600,
                                width: 32,
                                height: 32,
                                borderRadius: '50% 50% 50% 0',
                                backgroundColor: '#F29D38',
                                transformOrigin: 'bottom left',
                                transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                                '&::before': { display: 'none' },
                                '&.MuiSlider-valueLabelOpen': {
                                    transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                                },
                                '& > *': {
                                    transform: 'rotate(45deg)',
                                },
                            },
                        }
                        }
                    />
                </Grid>
                <Grid item container xs={10} md={12} sx={{
                    position: "relative",

                }}>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: cuotas.map((cuota, i) => `${cuota} ${cuota === 1 ? "cuota" : "cuotas"}`) }]}
                        yAxis={[{
                            tickFormat: (value) => `$${value}`
                        }]}
                        series={series}
                        width={700}
                        height={500}
                        colors={["#f68c3e"]}
                        bottomAxis={{}}
                        sx={{
                            //change left yAxis label styles
                            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                                strokeWidth: 2,
                                fill: "#fff",
                                fontWeight: 600,
                                zIndex: 9999
                            },
                            // leftAxis Line Styles
                            "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                                stroke: "#fff",
                                strokeWidth: 2
                            }
                        }}
                    />
                    <Box sx={{
                        width: "100%",
                        position: "absolute",
                        bottom: 95,
                        display: "flex",
                        justifyContent: "center",

                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "86%"

                        }}>
                            {cuotas.map((cuota, i) => <Typography sx={{
                                width: "8%",
                                color: "white",
                                textAlign: "center",
                                fontWeight: 600
                            }}>{cuota}</Typography>)}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid item md={6} xs={12} sx={{
                height: "80vh",
                borderRadius: 12,
                alignItems: "center",
            }}>
                <table style={{
                    color: "white",
                    borderRadius: '5px',
                    borderSpacing: '40px 40px',
                }}>
                    <thead style={{
                    }}>
                        <tr style={{
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 600,
                            marginBottom: 100,
                        }}>
                            <td style={{
                                padding: 10,
                                backgroundColor: "rgba(255, 255, 255, 0.4)",
                                borderRadius: '5px',
                            }}>
                                Cantidad cuotas
                            </td>
                            <td style={{
                                padding: 10,
                                backgroundColor: "rgba(255, 255, 255, 0.4)",
                                borderRadius: '5px',
                            }}>
                                Montos por cuota
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {cuotas.map((cuota, index) => (
                            <tr>
                                <th style={{
                                    padding: 10,
                                    backgroundColor: index % 2 === 0 ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.2)",
                                    borderRadius: '5px',
                                    textAlign: "center",
                                    fontWeight: 500
                                }}>
                                    {cuota} {cuota === 1 ? "cuota" : "cuotas"}
                                </th>
                                <th style={{
                                    padding: 10,
                                    backgroundColor: index % 2 === 0 ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.2)",
                                    borderRadius: '5px',
                                    fontWeight: 500
                                }}>
                                    ${series[0].data[index].toFixed(2)}
                                </th>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
                <Typography sx={{
                    color: "white",
                    fontSize: 18,
                    marginTop: 20,
                    textAlign: { xs: "center", md: "left" },
                    paddingBottom: { xs: 20, md: 0 }

                }}>
                    Sujeto a aprobación crediticia
                </Typography>
            </Grid>
        </Grid>
    )
}