import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function ContactoModal(props) {
    const { open, setOpen } = props;
    const [mensajesChat, setMensajesChat] = useState([]);
    const chatBottomRef = useRef();

    const mensajes = [
        { id: 0, mensaje: "Hola, soy tu asistente virtual.", persona: 1 },
        { id: 1, mensaje: "¿En qué puedo ayudarte?", persona: 1, opciones: [7, 9] },
        { id: 2, mensaje: "¿Dónde puedo encontrarlos?", persona: 2, siguiente: 3 },
        { id: 3, mensaje: "El local se encuentra en Corrientes 1374, Villa Maria", persona: 1, opciones: [4, 9] },
        { id: 4, mensaje: "Volver a comenzar", persona: 2, siguiente: 1 },
        { id: 5, mensaje: "¿Cuál es el horario de atención?", persona: 2, siguiente: 6 },
        { id: 6, mensaje: "El horario de atención es de 8:30 a 20:30 de lunes a viernes y los sabado de 8:30 a 12:30", persona: 1, opciones: [4, 9] },
        { id: 7, mensaje: "Quiero saber informacion acerca del local", persona: 1, siguiente: 8 },
        { id: 8, mensaje: "¿Qué información necesitas?", persona: 1, opciones: [2, 5, 9] },
        { id: 9, mensaje: "Comunicarme por WhatsApp", persona: 1, siguiente: 10 },
        { id: 10, mensaje: "¿Con quién quieres comunicarte?", persona: 1, siguiente: 11, opciones: [11, 12] },
        { id: 11, mensaje: "Comunicarme con Silvio", persona: 1, onClick: () => { window.open("https://wa.me/5493534457670?text=Hola%20Silvio,%20quiero%20informacion%20acerca%20de%20Tu%20Credito") } },
        { id: 12, mensaje: "Comunicarme con Janet", persona: 1, onClick: () => { window.open("https://wa.me/5493534457642?text=Hola%20Janet,%20quiero%20informacion%20acerca%20de%20Tu%20Credito") } },
        // { id: 2, mensaje: "Quiero solicitar un crédito", persona: 1, siguiente: 3 },
        // { id: 3, mensaje: "¿Cuánto dinero necesitas?", persona: 1, opciones: [4, 5, 6] },
        // { id: 4, mensaje: "1000", persona: 2, siguiente: 7 },
        // { id: 5, mensaje: "2000", persona: 2, siguiente: 7 },
        // { id: 6, mensaje: "3000", persona: 2, siguiente: 7 },
        // { id: 7, mensaje: "¿En cuántas cuotas?", persona: 1, opciones: [8, 9, 10] },
        // { id: 8, mensaje: "3", persona: 2, siguiente: 11 },
        // { id: 9, mensaje: "6", persona: 2, siguiente: 11 },
        // { id: 10, mensaje: "12", persona: 2, siguiente: 11 },
        // { id: 11, mensaje: "Como deseas que sigamos", persona: 1, opciones: [12, 13] },
        // { id: 12, mensaje: "Por WhatsApp", persona: 2 },
        // { id: 13, mensaje: "Volver a empezar", persona: 2, siguiente: 1 },

    ]

    const mensajesInicio = [0, 1]

    useEffect(() => {
        let mensajesIniciales = []
        mensajesInicio.map(mensajeInicio => {
            mensajesIniciales.push(mensajes[mensajeInicio])
        })

        setMensajesChat(mensajesIniciales)
    }, [])

    useEffect(() => {
        chatBottomRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [mensajesChat])

    function handleSelectOpcion(opcion) {
        let mensajesChatCopy = [...mensajesChat]
        opcion.persona = 2
        mensajesChatCopy.push(opcion)
        if (opcion.siguiente) {
            mensajesChatCopy.push(mensajes[opcion.siguiente])
        }
        setMensajesChat(mensajesChatCopy)

    }

    const MensajeComponent = ({ mensaje }) => {
        return (
            <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: mensaje.persona === 1 ? "flex-start" : "flex-end",
            }}>
                <Box sx={{
                    padding: 2,
                    borderRadius: "20px",
                    maxWidth: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: 2,
                    backgroundColor: mensaje.persona === 1 ? "#F68C3E" : "#394C6C",
                }}>
                    <Typography color={'white'} variant="body1">{mensaje.mensaje}</Typography>
                </Box>
            </Box>
        )
    }

    const MensajeOpcionComponent = ({ mensaje }) => {
        return (
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                }}>
                <Box
                    onClick={mensaje.onClick ? mensaje.onClick : () => handleSelectOpcion(mensaje)}
                    sx={{
                        padding: 2,
                        borderRadius: "40px",
                        cursor: "pointer",
                        maxWidth: "70%",
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 2,
                        border: "1px solid #F68C3E",
                    }}>
                    <Typography color={'white'} variant="body1">{mensaje.mensaje}</Typography>
                </Box>
            </Box>
        )
    }


    if (!open) return null;

    return (
        <Box sx={{
            position: "fixed",
            bottom: { xs: 0, md: 60 },
            right: { xs: 0, md: 60 },
            borderRadius: { xs: 0, md: "30px" },
            width: { xs: "100%", md: "370px" },
            height: { xs: "100%", md: "600px" },
            boxShadow: "0 0 20px rgba(246, 140, 62, .6)",
            zIndex: 100,
        }}>
            <Box sx={{
                backgroundColor: "rgba(242, 157, 56, 1)",
                height: "80px",
                display: "flex",
                justifyContent: "space-between",
                paddingX: 2,
                borderRadius: { md: "30px 30px 0 0" },
                alignItems: "center",
                zIndex: 100,
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "2px"
                    }}>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="43.439" height="44" rx="21.7195" fill="white" />
                            <path d="M30.1226 25.363H13.3651C12.4237 25.363 11.6606 26.1261 11.6606 27.0675C11.6606 28.0088 12.4237 28.7719 13.3651 28.7719H30.1226C31.0639 28.7719 31.827 28.0088 31.827 27.0675C31.827 26.1261 31.0639 25.363 30.1226 25.363Z" fill="#F68C3E" />
                            <path d="M26.6137 19.5591H16.8792C15.9379 19.5591 15.1748 20.3222 15.1748 21.2635C15.1748 22.2048 15.9379 22.9679 16.8792 22.9679H26.6137C27.555 22.9679 28.3181 22.2048 28.3181 21.2635C28.3181 20.3222 27.555 19.5591 26.6137 19.5591Z" fill="#F68C3E" />
                            <path d="M23.5411 13.7551H19.95C19.0087 13.7551 18.2456 14.5182 18.2456 15.4595C18.2456 16.4009 19.0087 17.164 19.95 17.164H23.5411C24.4825 17.164 25.2456 16.4009 25.2456 15.4595C25.2456 14.5182 24.4825 13.7551 23.5411 13.7551Z" fill="#F68C3E" />
                            <path d="M38.5919 19.8713C37.2993 19.8713 36.2262 18.9125 35.8509 17.6756C33.997 11.5648 28.3758 7.10926 21.7453 7.10926C15.1147 7.10926 9.49342 11.5649 7.63958 17.6757C7.26435 18.9125 6.19127 19.8713 4.89876 19.8713V19.8713C3.35083 19.8713 2.1318 18.5202 2.51734 17.0211C4.7368 8.39085 12.5155 2 21.7453 2C30.9751 2 38.7544 8.39088 40.9734 17.0211C41.3588 18.5203 40.1398 19.8713 38.5919 19.8713V19.8713Z" fill="#394C6C" />
                            <path d="M23.9336 39.0881C23.9336 37.7994 24.8861 36.7284 26.1153 36.3412C30.7556 34.8797 34.4211 31.1558 35.8493 26.4476C36.2246 25.2107 37.2976 24.252 38.5902 24.252V24.252C40.1381 24.252 41.357 25.6032 40.9715 27.1023C39.1717 34.1007 33.7155 39.6257 26.7944 41.4647C25.2929 41.8637 23.9336 40.6417 23.9336 39.0881V39.0881Z" fill="#394C6C" />
                            <path d="M4.89853 24.2495C6.19115 24.2495 7.26426 25.2083 7.63953 26.4453C9.0679 31.1534 12.7336 34.8776 17.3734 36.3389C18.6027 36.726 19.5552 37.797 19.5552 39.0858V39.0858C19.5552 40.6393 18.196 41.8612 16.6945 41.4623C9.77327 39.6234 4.31655 34.0983 2.51711 27.0998C2.13166 25.6007 3.35067 24.2495 4.89853 24.2495V24.2495Z" fill="#394C6C" />
                        </svg>

                    </Box>
                    <Typography variant="h5" fontWeight={600} color="white">Tu Crédito</Typography>
                </Box>

                <Box
                    onClick={() => setOpen(false)}
                    sx={{
                        color: "white",
                        borderRadius: 3,
                        padding: "6px",
                        cursor: "pointer",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        '&:hover': {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                        }
                    }}>
                    <CloseIcon />
                </Box>
            </Box>
            <Box
                sx={{
                    height: { xs: "calc(100% - 80px)", md: "calc(600px - 80px)" },
                    backdropFilter: "blur(30px)",
                    borderRadius: "0 0 30px 30px",
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        display: "none",
                    },
                    zIndex: 100,
                }}>
                <Box sx={{
                    padding: 2,
                }}>
                    {mensajesChat.map((mensaje, index) => <MensajeComponent key={index} mensaje={mensaje} />)}
                    {mensajesChat[mensajesChat.length - 1]?.opciones &&
                        mensajesChat[mensajesChat.length - 1].opciones.map(opcion => <MensajeOpcionComponent key={opcion} mensaje={mensajes[opcion]} />)}
                </Box>
                <Box ref={chatBottomRef} />
            </Box>
        </Box >
    )
}